<template>
  <div>
    <v-row>
      <v-col sm="4" md="3" lg="2"
        ><v-card class="mx-auto" width="256" tile>
          <v-navigation-drawer permanent>
            <v-system-bar></v-system-bar>

            <v-divider name="divider"></v-divider>
            <v-list nav dense>
              <v-list-item-group v-model="selectedItem" color="primary">
                <v-list-item
                  v-for="(item, i) in items"
                  :key="i"
                  link
                  :to="item.href"
                  active-class="border"
                  :disabled="item.disabled"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-navigation-drawer> </v-card
      ></v-col>
      <v-col sm="8" md="9" lg="10"><router-view></router-view></v-col
    ></v-row>
  </div>
</template>

<script>
export default {
  name: "",
  props: [""],
  components: {},
  data: () => ({
    selectedItem: 0,
    items: [
      { text: "资产", icon: "mdi-folder", href: "/domain/list" },
      {
        text: "地址资源池",
        icon: "mdi-account-check",
        href: "/domain/address_pool",
      },

      // {
      //   text: "分配清单",
      //   icon: "mdi-file-tree",
      //   href: "/domain/address_alloc",
      // },
      {
        text: "规则映射",
        icon: "mdi-message-video",
        href: "/domain/rules_mapping",
      },
      // {
      //   text: "规则下发",
      //   icon: "mdi-checkbox-multiple-blank-circle-outline",
      //   href: "/domain/rules",
      // },
      {
        text: "认证管理",
        icon: "mdi-shield-half-full",
        href: "/domain/demo",
        disabled: true
      },
      {
        text: "访问控制",
        icon: "mdi-shuffle",
        href: "/domain/demo",
        disabled: true
      },
      {
        text: "预警",
        icon: "mdi-chart-bubble",
        href: "/domain/demo",
        disabled: true
      },
      {
        text: "统计分析",
        icon: "mdi-chart-bar-stacked",
        href: "/domain/demo",
        disabled: true
      },
    ],
  }),
  mounted() {},
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style scoped>
.border {
  border-left: 4px solid #0ba518;
}
</style>
